<template>

  <div class="page-cu-container">
    <div class="page-cu-main">
      <a-row type="flex" class="page-cu-row">
        <a-col :span="24" class="page-cu-left">
          <div class="page-cu-left-head">
          </div>
          <div class="page-cu-left-table">
            <vuescroll>

              <a-form-model
                :destroyOnClose="true"
                ref="highSeas"
                :rules="highSeasRules"
                :model="highSeas"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 12 }"
              >
                <a-form-model-item label="说明">
                  <a-alert message="回归公海超时未联系时间(单位：月)" type="success" />
                </a-form-model-item>
                <a-form-model-item
                  label="时间"
                  prop="high_seas"
                >
                  <a-input v-model="highSeas.high_seas"></a-input>
                </a-form-model-item>
                <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                  <a-button type="primary" @click="setInstallationDetail">确定</a-button>
                  <a-button style="margin-left: 20px;">重置</a-button>
                </a-form-model-item>
              </a-form-model>
            </vuescroll>
          </div>
        </a-col>
      </a-row>
    </div>

  </div>
</template>

<script>
  import * as Api from './api';
  import axios from 'axios';

  export default {
    name: "index",
    data() {
      return {

        highSeas: {
          id: '',
          high_seas: ''
        },
        highSeasRules: {
          high_seas: [
            { required: true, message: "请输入回归公海超时未联系时间", trigger: "change" },
          ]
        },
      }
    },
    mounted() {
      this.getInstallationDetail();
    },
    methods: {

      /**
       * 获取数据
       * @author wheat
       * */
        async getInstallationDetail() {
          let res = await Api.InstallationDetail({});
          this.highSeas = res.target;
        },
      /**
       * 通过
       * @author wheat
       * */
      async setInstallationDetail(id) {
        this.$refs['highSeas'].validate(async (valid) => {
          if (valid) {
            let res = await Api.InstallationUpdate(this.highSeas);
            if (res && res["code"] == "0") {
              this.$message.success(res.message);
              this.getList();
            } else {
              this.$message.error(res.message);
            }
          }
        });
      }
    }
  }
</script>

<style scoped>
.cu-table
{
  border-collapse:collapse;
  width: 100%;
}

.cu-table,th, td
{
  border: 1px solid #000000;
  padding: 10px;
}
.field-left {
  width: 120px;
}
</style>
