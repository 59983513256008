import request from '@/utils/request';

// 获取公海配置
export function InstallationDetail(params) {
  return request({
    url: '/admin/message/highSeas/detail',
    method: 'GET',
    params
  });
}


// 设置公海配置
export function InstallationUpdate(data) {
  return request({
    url: '/admin/message/highSeas/update',
    method: 'POST',
    data
  });
}
